<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <div v-if="config.type === 'open'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否启用此账号?</span>
        </div>
      </div>
      <div v-if="config.type === 'close'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否停用此账号?</span>
        </div>
        <div class="modal_text modal_stext">停用此账号后，此账号将无法登录</div>
      </div>
      <div v-if="config.type === 'reset'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否重置此账号密码?</span>
        </div>
        <div class="modal_text modal_reset">
          账号名称:{{ config.record.username }}
        </div>
        <div class="modal_text modal_reset">
          手机号码:{{ config.record.phone }}
        </div>
        <div class="modal_text modal_reset">
          重置后初始密码为:{{ password }}
        </div>
      </div>
      <div v-if="config.type === 'edit'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="账号名称" prop="username">
            <a-input v-model="form.username" />
          </a-form-model-item>
          <a-form-model-item label="手机号码" prop="phone">
            <a-input v-model="form.phone" />
          </a-form-model-item>
        </a-form-model>
      </div>

      <div v-if="config.type === 'addvisit'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="回访时间" prop="revisitDay">
            <a-date-picker
              style="width: 100%"
              v-model="form.revisitDay"
              @change="onTimeChange"
            />
          </a-form-model-item>
          <a-form-model-item label="回访结果" prop="revisitResult">
            <a-textarea
              :maxLength="100"
              v-model="form.revisitResult"
              placeholder="请输入"
              @change="remarksOnchange"
              :auto-size="{ minRows: 8, maxRows: 8 }"
            />
            <span style="position: absolute; right: 10px; bottom: 10px"
              >已输入{{ detLength }}/100</span
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-if="config.type === 'editvisit'">
        <div style="margin-bottom: 20px">
          <div class="notice_text">
            <span style="color: red; font-weight: 400">* </span>
            鼠标双击选择数据
          </div>
          <a-table
            :loading="loading"
            bordered
            :rowKey="(record) => record.id"
            :columns="columns"
            :customRow="rowClick"
            :data-source="dataTable"
            :rowClassName="setRowClassName"
            size="small"
            :scroll="{ y: 600 }"
            :pagination="false"
          >
            <span slot="action" slot-scope="text, record">
              <a @click="delVisit(record)">删除</a>
            </span>
          </a-table>
        </div>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="config.type === 'addvisit' ? labelCol : labelCol2"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="回访时间" prop="revisitDay">
            <a-date-picker
              :disabled="selectStatus"
              style="width: 100%"
              v-model="form.revisitDay"
              @change="onTimeChange"
            />
          </a-form-model-item>
          <a-form-model-item label="回访结果" prop="revisitResult">
            <a-textarea
              :disabled="selectStatus"
              :maxLength="100"
              v-model="form.revisitResult"
              placeholder="请输入"
              @change="remarksOnchange"
              :auto-size="{ minRows: 8, maxRows: 8 }"
            />
            <span style="position: absolute; right: 10px; bottom: -10px"
              >已输入{{ detLength }}/100</span
            >
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { accountManageApi } from "@/api/accountManage.js";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    //校验手机号
    let validatorPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号码不能为空"));
      } else if (!/^1[3456789]\d{9}$/.test(value) && value) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      confirmLoading: false,
      selectStatus: true,
      tempId: null,
      tempRowsion: null,
      detailId: null,
      idArr: [],
      password: "",
      detLength: 0,
      labelCol2: { span: 2 },
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      form: {
        username: "",
        phone: "",
        revisitDay: null,
        revisitResult: "",
      },
      dataTable: [],
      rules: {
        revisitResult: [
          { required: true, message: "回访结果不能为空", trigger: "blur" },
        ],
        revisitDay: [
          { required: true, message: "回访时间不能为空", trigger: "change" },
        ],
        username: [
          { required: true, message: "账号名称不能为空", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: validatorPhone,
          },
        ],
      },
      columns: [
        {
          title: "回访时间",
          dataIndex: "revisitDay",
          key: "revisitDay",
          align: "center",
        },
        {
          title: "回访人",
          dataIndex: "visitor",
          key: "visitor",
          align: "center",
        },

        {
          ellipsis: true,
          title: "回访结果",
          dataIndex: "revisitResult",
          key: "revisitResult",
          align: "center",
          width: "400px",
        },

        {
          title: "操作",
          align: "center",
          key: "action",
          width: "100px",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.inintModal();
  },
  watch: {},
  methods: {
    setRowClassName(record) {
      if (this.tempId === record.id) {
        return "clickRowStyl";
      } else {
        return "";
      }
    },
    inintModal() {
      if (this.config.type === "editvisit") {
        this.idArr = [];
        this.getRevisits();
      } else if (this.config.type === "reset") {
        this.getRandoms();
      }
    },
    //删除回访记录
    delVisit(record) {
      if (record.id === this.tempId) {
        this.form.revisitResult = "";
        this.form.revisitDay = "";
        this.selectStatus = true;
      }
      this.idArr.push(record.id);
      this.dataTable = this.dataTable.filter((v) => {
        return v.id != record.id;
      });
    },
    remarksOnchange() {
      this.$nextTick(() => {
        this.detLength = this.form.revisitResult.length;
      });
    },
    rowClick(record) {
      return {
        on: {
          dblclick: () => {
            this.tempId = record.id;
            this.form.revisitResult = record.revisitResult;
            this.form.revisitDay = record.revisitDay;
            this.$nextTick(() => {
              this.detLength = this.form.revisitResult.length;
            });
            this.tempRowsion = record.rowVersion;
            this.selectStatus = false;
            this.$refs.ruleForm.validate(() => {});
          },
        },
      };
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.form.revisitDay = dateStrings;
    },
    //获取随机码
    getRandoms() {
      let id = this.config.id;
      accountManageApi.getRandoms(id).then((res) => {
        if (res.success) {
          this.password = res.data;
        } else {
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //获取回访记录列表
    getRevisits() {
      this.loading = true;
      let id = this.config.id;
      accountManageApi.getRevisits(id).then((res) => {
        if (res.success) {
          this.dataTable = res.data;
          this.loading = false;
        } else {
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
          this.loading = false;
        }
      });
    },
    //修改账户接口
    editAccount() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        phone: this.form.phone,
        username: this.form.username,
        rowVersion: this.config.record.rowVersion,
      };
      accountManageApi.editAccount(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },
    //停用接口
    closeAccount() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        status: this.config.type === "open" ? 1 : 0,
      };
      accountManageApi.closeAccount(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //编辑回访记录
    editVisitAccount() {
      this.confirmLoading = true;
      let params = {
        deleteRevisits: this.idArr,
      };
      if (this.tempId) {
        params.revisits = [
          {
            id: this.tempId,
            revisitDay: this.form.revisitDay,
            revisitResult: this.form.revisitResult,
            rowVersion: this.tempRowsion,
          },
        ];
      } else {
        params.revisits = [];
      }

      accountManageApi.editVisitAccount(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //添加回访记录
    addVisitAccount() {
      this.confirmLoading = true;
      let params = {
        frontUserId: this.config.id,
        revisitDay: this.form.revisitDay,
        revisitResult: this.form.revisitResult,
      };
      accountManageApi.addVisitAccount(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //重置密码接口
    resetAccount() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        rowVersion: this.config.record.rowVersion,
      };
      accountManageApi.resetAccount(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    handleOk() {
      if (this.config.type === "reset") {
        this.resetAccount();
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editAccount();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "addvisit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addVisitAccount();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "editvisit") {
        if (this.selectStatus) {
          this.editVisitAccount();
        } else {
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              this.editVisitAccount();
            } else {
              return false;
            }
          });
        }
      } else {
        this.closeAccount();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}
.add_positon {
  position: relative;
}
.modal_content {
  display: flex;
}
.modal_left {
  min-width: 120px;
  text-align: right;
}
.modal_right {
  padding-bottom: 10px;
  flex-grow: 1;
}
.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}
.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 36px;
  font-weight: 200;

  margin-bottom: 20px;
}

.header_contain {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px; */
  padding: 0 40px;
  padding-top: 20px;
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
</style>
<style>
.clickRowStyl {
  background-color: #52bbdb;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #00ed20;
}
</style>