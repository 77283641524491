<template>
  <div>
    <div class="search_container">
      <div>
        <span>账户名称：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="username"
        />
      </div>
      <div>
        <span>手机号码：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="phone"
        />
      </div>

      <div>
        <span>最新回访时间：</span>

        <a-date-picker
          v-model="revisitDay"
          class="input_width"
          @change="onTimeChange"
        />
      </div>
      <div>
        <span>是否回访：</span>
        <a-select v-model="isRevisit" class="input_width">
          <a-select-option
            v-for="(item, index) in isRevisitList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="search_container add_button">
      <div>
        <span>认证情况：</span>
        <a-select v-model="type" class="input_width">
          <a-select-option
            v-for="(item, index) in typeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>

      <div>
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
          :scroll="{ y: 379 }"
      >
        <span slot="authTypes" slot-scope="text">
          <span v-if="text === 1">普通用户</span>
          <span v-if="text === 2">企业用户</span>
          <span v-if="text === 3">开发者用户</span>
        </span>
        <span slot="status" slot-scope="text">
          <span style="color: #52c41a" v-if="text === 1">启用中</span>
          <span style="color: #ff4d4f" v-if="text === 0">已停用</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a v-if="record.status === 1" @click="openModel('edit', record)"
            >修改</a
          >
          <a
            style="margin-left: 20px"
            v-if="record.status === 1"
            @click="openModel('reset', record)"
            >重置密码</a
          >
          <a style="margin-left: 20px" v-if="record.status === 1">
            <a-popover placement="bottomRight">
              <template slot="content">
                <div
                  @click="openModel('addvisit', record)"
                  class="popover_contain"
                >
                  添加回访记录
                </div>
                <div
                  @click="openModel('editvisit', record)"
                  class="popover_contain"
                >
                  编辑回访记录
                </div>
                <div
                  @click="openModel('close', record)"
                  class="popover_contain"
                >
                  停用
                </div>
              </template>
              <a-icon type="ellipsis" />
            </a-popover>
          </a>
          <a v-if="record.status === 0" @click="openModel('open', record)"
            >启用</a
          >
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { accountManageApi } from "@/api/accountManage.js";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
  },
  data() {
    return {
      loading: false,
      username: "",
      revisitDay: null,
      phone: "",
      type: 1,
      isRevisit: 2,
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "人员班组",
        width: "60%",
        okText: "保存",
        delArr: [],
        status: false,
      },
      typeList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "普通用户",
          value: 1,
        },
        {
          name: "企业用户",
          value: 2,
        },
        {
          name: "开发者用户",
          value: 3,
        },
      ],
      isRevisitList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "是",
          value: 1,
        },
        {
          name: "否",
          value: 2,
        },
      ],
      columns: [
        {
          title: "账号名称",
          dataIndex: "username",
          key: "username",
          align: "center",
        },

        {
          title: "手机号码",
          dataIndex: "phone",
          key: "phone",
          align: "center",
          // width: "110px",
        },
        {
          title: "注册时间",
          dataIndex: "createTime",
          key: "createTime",
          align: "center",
          // scopedSlots: { customRender: "status" },
          // width: "110px",
        },
        {
          title: "最近回访时间",
          dataIndex: "modifyTime",
          key: "modifyTime",
          align: "center",
          // width: "160px",
        },
        {
          title: "账号状态",
          dataIndex: "status",
          key: "status",
          align: "center",
          scopedSlots: { customRender: "status" },
          // width: "160px",
        },

        {
          title: "认证情况",
          dataIndex: "authTypes",
          key: "authTypes",
          align: "center",
          // scopedSlots: { customRender: "authTypes" },
          // width: "160px",
        },

        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.revisitDay = dateStrings;
    },
    //重置
    reset() {
      this.username = "";
      this.revisitDay = "";
      this.phone = "";
      this.type = 1;
      this.isRevisit = 2;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        username: this.username,
        revisitDay: this.revisitDay,
        type: this.type,
        phone: this.phone,
        isRevisit: this.isRevisit, //企业账户 1，企业开发者 2，个人开发者 3
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      accountManageApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },

    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "edit") {
        this.modelConfig.record = record;
        this.modelConfig.title = "修改账户信息";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
        this.modelConfig.status = false;
      } else if (type === "open") {
        this.modelConfig.record = record;
        this.modelConfig.title = "启用账号";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "reset") {
        this.modelConfig.record = record;
        this.modelConfig.title = "重置账号";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "close") {
        this.modelConfig.record = record;
        this.modelConfig.title = "停用账号";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "addvisit") {
        this.modelConfig.record = record;
        this.modelConfig.title = "添加回访记录";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "保存";
      } else {
        this.modelConfig.record = record;
        this.modelConfig.title = "编辑回访记录";
        this.modelConfig.width = "1000px";
        this.modelConfig.okText = "保存";
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.modelConfig.show = false;
      if (this.modelConfig.type === "open") {
        this.$message.success("启用成功！");
      } else if (this.modelConfig.type === "close") {
        this.$message.success("停用成功！");
      } else {
        this.$message.success("保存成功！");
      }

      this.reset();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 240px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
  /deep/.ant-table {
    min-height: 379px;
    }
</style>