import axios from "@/config/http";

export const accountManageApi = {
  // 获取账号管理table数据
  getTableData(params) {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/user/front/account",
      { params }
    );
  },
  // 获取回访记录table数据
  getRevisits(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/user/front/account/revisit-record/${params}`
    );
  },
  // 获取随机码
  getRandoms(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/user/front/password/random/${params}`
    );
  },
  //新增回访记录
  addVisitAccount(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/user/front/account/revisit-record",
      params
    );
  },
  //编辑回访记录
  editVisitAccount(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/user/front/account/revisit-record",
      params
    );
  },
  //停用账户信息
  closeAccount(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/user/front/account/enable",
      params
    );
  },
  //修改账户信息
  editAccount(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/user/front/account",
      params
    );
  },
  //重置密码
  resetAccount(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/user/front/password/reset",
      params
    );
  },
  // //删除技能
  // deleteTeam(params) {
  //   return axios.delete(`maintenance/skill/${params}`);
  // },
};
